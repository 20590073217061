import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Coming soon...</p>
        <p>
            Amazing apps created with React
        </p>
        <div>
            by 
            <a
            className="App-link"
            href="https://adrianmanduc.com"
            target="_blank"
            rel="noopener noreferrer"
            style={{marginLeft: '10px'}}
            >
            Adrian Manduc
            </a>
        </div>
      </header>
    </div>
  );
}

export default App;
